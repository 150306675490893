import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`A unified Chamber of Commerce`}</h2>
    <p>{`Korea is a powerhouse in trade with limited natural resources. An interesting market for the resource rich bloc of Latin America. `}</p>
    <p>{`The Latin American Chamber of Commerce grouped together to support enterprises entering Korea, a country where trust from verified contacts is key to business.`}</p>
    <h3>{`A change of style`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "626px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6fa9c55f7c523d4e906819edc8492a9e/af590/before-after_8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACJUlEQVQoz4VQu24TQRTdr6ChoaSg4QMIQkKiwRIFBbHE4z8gCksRkaSh4gOoEIpo0oGEFF4KDytOIiMcJyLB9u567LV3Zp3YM/eFZpwC0jDSke6cc+bceycqtL5RaL2ijVnsqX7cTdO4m6RxfzCIC60fF1ovFVpfKLS+WGj91HPD0SjuJknc6SZxPhx632Kh9XKh9dXIlOVzY4yU47EkaSq/Do8CekoFzphSTFleN2V525Rl4PqDXJIkkXa7HergK4NvKSKiZWYWEZmICPwFJyLWa4h4hYhu+pqIrdfGEwsjcxx8RDSZafQwYuZVIvKPnHPAgMSAyADA4C8z4xwRVU4b4/TE8uv1TX7x6j1nufEkeI2ZFyIRWR0VRo46GQxUj1XnUFQ/l2FRshqM6PjYDy5ziFghpBDYebPDydu6qPqBrK19CIF+KGKaBaYql81aA9oHTW593ZCtrR3Z3v3J240WaTMOgQBYkdMJhZhh/btMX34SMSd8+kV+k4WIVWvV6EKyTLk0zTjNeqx6ipXy6JO1LgQycwWJZJAb3O8objbb3Prxmw+znJsHXZjakLkQwVFtBSZjsQ6sc448AMADnbWzVYjmRKQCgNLYa8O3+j5+fFenLxs7VKvv0+fanp1MQ+NHEYs8k/8cIryGRLf+IftGJBme8dGTCN30MiI+QMQ7iDh/FkR0FxDPIeJ5IrrnAOatc1UQqaJI1QFWrXXzSHQfES/9AXiG0w21NK8+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Brand design",
            "title": "Brand design",
            "src": "/static/6fa9c55f7c523d4e906819edc8492a9e/af590/before-after_8.png",
            "srcSet": ["/static/6fa9c55f7c523d4e906819edc8492a9e/772e8/before-after_8.png 200w", "/static/6fa9c55f7c523d4e906819edc8492a9e/e17e5/before-after_8.png 400w", "/static/6fa9c55f7c523d4e906819edc8492a9e/af590/before-after_8.png 626w"],
            "sizes": "(max-width: 626px) 100vw, 626px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The Latamcham had to represent various countries with very diverse history and style yet maintain references to the local market. To achieve this the rose, one of Colombia's and Ecuador's biggest exports was chosen as the core isotype. The colors needed to reference the `}<em parentName="p">{`Taegukgi`}</em>{`, Korea's flag, and their national interpretation of the YingYang. `}</p>
    <div className="row my-5">
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/58bdc4d4ec84002c61360df262089146/df56e/latamcham_1.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACW0lEQVQ4y62UyYrUUBSG+znEh3HhQvEBfAVBcKEbceVO0E3vHFAEUWwQR1zZiILQSqs4dFe6MlRVqMyVVG6SmztPkkrbk+3GNoRwF/nuf/5z/nuXzBGepSPDghsMTTPnlFaQECYO/VUbo7XuFm2t88gI3sHs1b32winx9km7tjryUy8u/ySjFMRFayJP+TZ7eReeOS6tzwv42S107oQOR6YujFYHMKU6tYfPP91c+QDCgL9/ge9cJQ+uqyzoYCUEx4QJJfdjTEjKRF+qMQYRBog0ZartrwsTC8+IUD+Ix34wnYZlWQqx7RliWja4VyZURClI8yqYNWFJZnm1DRPKklmRlSDKC4iwWTSGCYkoR5RT3hWECQ/jchrNp2HhB3mYgm0YQrg5GAxdZ8MaOK4jeKfMuKhb0iCKKe8GonQrFJWaSK30nlE1EFqW5bmebdvj0Ugp1RtuCYeI9TCv5o31rZ04aOJIWKsdmFA2K6oKYgARgEhrrbRGhCPCIWaIcKmNYdQUiWlKUxeaoH3K34ebG/bgx9amNXGElL1trRdfKY1Sf01Yi1CUpXMAkjQDoNqTKb0/Ynr33Vt2mGRhnEXJLCmA6HV+J1FufVFT5+BGOzBCyHFdz/PcsR+7Nrp/TT26YQhS6VT+/AhPH8NXzipGuZCCcy4kF3IXVkqHaZ3P2wQQnsVideXypeX1d2tm7TV9ers9f5ItX6xL4I38yXDD8cZBlPaxW+p7I4TsJ9QbWrciO5ybMmNvHqs8MgwrKTEmhGCMMWP8kPOsdd8V1Uf3Hy+Dfk6HNPw/3iS/AJBBdaqLxejnAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Original design",
                "title": "Original design",
                "src": "/static/58bdc4d4ec84002c61360df262089146/5a190/latamcham_1.png",
                "srcSet": ["/static/58bdc4d4ec84002c61360df262089146/772e8/latamcham_1.png 200w", "/static/58bdc4d4ec84002c61360df262089146/e17e5/latamcham_1.png 400w", "/static/58bdc4d4ec84002c61360df262089146/5a190/latamcham_1.png 800w", "/static/58bdc4d4ec84002c61360df262089146/df56e/latamcham_1.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-md-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c4bf35eb9f10e637d0e3528ac3c147da/df56e/latamcham_2.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADAElEQVQ4y2P4TwFg+P///z9yAQNFNt968DyuYlp85XQQqpieUDkDykZCcVUz83Mby5Jzi5Nzc+LTchMz0iIS50ycwXDs/C0uwwRe4yR+02Q+02QuwwRMxG6UpK/ibiagrMwrrcQrpSYgK8ssnB2ZwnDy0h0l1wI9/3Jt31J1zyJ55zwZhxxp+2w4krHPlnDIcTeNjNB0slc2tZIzcFazsJc27MyuYTh+4baaZ5F9TKNxUKW2b5mOb5mUXZaEbSYcSdpmCtllxesl9ahFZWr4RZh6eWnauUqZTslpBmlWcS+0iqw3Da3R9C7R9C7B1CxslxWpE9eoFJRjGDKtvDPNIjhY1nZ6TgtIsypYs1lotY5vmaZXiSQ2zSG6MbXKgdkGwVkWoRVGUdmynotzusGaPQrtYhotw+t0/crUPIuQdcI1R2nHtymEFGoFp2h6l2mFlsr7L8hoZzhx8Y6sY46GV7G6Z5GaByjAsGi2z0rQTuqViyhTCcrW8CvSC8uS9Zid3sxw6tIdUet0Uet0cesMMesMMZsMkAa7LDiSsssSdsjO1EmdJR3VLhvSqhDaoxHXLhG0PrUHFFUStpnSDtkyDtnSDtlS9lniNhm8JkncRokQxGecyGSS0KiQ9dik9aZTz3XdhktiRec5sx/EzmU4cfG2rFOuulexulexhnexoku+nFPuvLUH9hy/su3Qhe2HL2w7eG7b6auHI6bedO29k7bgduysy1atJ9nS7sTNYTh1+S7IbfYgJO2QLW6Toe5VfOfB8//fv/358f3v7x///vz9////k7I1V6zbbkfNvB0x/bJhw2m+7Lvxc0HJk0M/jtMgntMwnsswgU03VsQq/dL1Bx+fv7t758mL1+8/fPhy69Xbh9lLjzHEnGJPP8mccoor4zhDwq3gqQy3Hz5Pqp6RXDMzuWZmSu3MxOoZWU3znr16/////98/f//79+/vn79//v9/vfDo7aiZd1MX3E2efzdl/u3Imc8n7aYsS/779//vv39oCItCsDo0RJHNAAIiuse7e1knAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Redesign",
                "title": "Redesign",
                "src": "/static/c4bf35eb9f10e637d0e3528ac3c147da/5a190/latamcham_2.png",
                "srcSet": ["/static/c4bf35eb9f10e637d0e3528ac3c147da/772e8/latamcham_2.png 200w", "/static/c4bf35eb9f10e637d0e3528ac3c147da/e17e5/latamcham_2.png 400w", "/static/c4bf35eb9f10e637d0e3528ac3c147da/5a190/latamcham_2.png 800w", "/static/c4bf35eb9f10e637d0e3528ac3c147da/df56e/latamcham_2.png 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fce1d1af8a40562093a3682336af2235/df56e/latamcham_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABVUlEQVQ4y81Uy2rDMBDM//9JSnpvQnso9NJLCqUlPhRMiYljyXYkWbL12nWJ5bgkaXGgBDona7XDjHYHT9o/YPK/yc45pZTs4L3HA8bJiBhF0WI+v5lOb2ez3W4XiuPKoYlSut1uCSFlUQghqqoSQhhjLrKttZZSlmWptVZKcc4ZY8656w8MD/7DQ4bvSbhpuzMChAtEBGvR+3PCkTJ4kLLGjg/eQ7cJcN7Vjdf6Att75umUwTr0EE6MsbChX9/sAXhVMyHtwe2wqs1mE8cxY4wQwjk/Ue5iZF3++JbdLbXSQzFAKUUpzfM8yzJKqTHm2HbbWlE396/N4sWkbCgGZUJIHMdJkqzX6yRJ0jTtE4aITaOtsZqK8vmDPkU6404eiYeE7FHsIYTolb2HjBZ1VctVWizei4eVWH7aUg7iI9MG6JtAO2jsjwk/wVnC8Lu3bfHqP4MvbkuYBxYY/wkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Redesign",
            "title": "Redesign",
            "src": "/static/fce1d1af8a40562093a3682336af2235/5a190/latamcham_3.png",
            "srcSet": ["/static/fce1d1af8a40562093a3682336af2235/772e8/latamcham_3.png 200w", "/static/fce1d1af8a40562093a3682336af2235/e17e5/latamcham_3.png 400w", "/static/fce1d1af8a40562093a3682336af2235/5a190/latamcham_3.png 800w", "/static/fce1d1af8a40562093a3682336af2235/df56e/latamcham_3.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c981a068d0608f744ac775998e4ebb6f/df56e/latamcham_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABvUlEQVQ4y7WUv2sUQRTHU1oIFhotDFoIJlio/4UgiFExErAIngSioqL4A8VKLAwxJgj5Cyz8FySF1gpGUSzOCygce+zs7Py6m7nd2ffmye6RKyTJFolfvgxvis/MezNvZoR2oJHdhEMIiDgYB0FADFVQFAUibg9jBUKgQJU9QFZ4IlJKOudq0mYs7nSiwntEICL5K1pfa5aBFNbWwVLJKOporRABiViz3f7SyhG0UtbamprzLDNG53mGCN77brdnlAEErVVN2iWcZ33nnLPG6Kxn0WXg8yptWbOz954xFpdibMPVhEVR5L3/D/eMGIjo64/W1bkXjTvz126/HPr63fnp2eeLK++qujaDoWqA1Y+f9x8/P3Zq6vDJy0MfOX1l37FzU41nAzhsLPAv/P7Dp71Hzx6cuDA6PnlgfHJ0ovShExf3jJ25NPN0S3iQ9refrca9hZtPlks/Xr7x8PXcg8Vbj5Zm779aePM2VG2zCTxUxrWNFf/DEi616RJRz7q1780k4f1+f7vTBgCXasOlioUQJZz7XGmz/rsds7imSQCAizQVPBVpKc55kqScSymESGte1U4/g7C1dvMn+Qthl1P4OsXlxwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Redesign",
            "title": "Redesign",
            "src": "/static/c981a068d0608f744ac775998e4ebb6f/5a190/latamcham_4.png",
            "srcSet": ["/static/c981a068d0608f744ac775998e4ebb6f/772e8/latamcham_4.png 200w", "/static/c981a068d0608f744ac775998e4ebb6f/e17e5/latamcham_4.png 400w", "/static/c981a068d0608f744ac775998e4ebb6f/5a190/latamcham_4.png 800w", "/static/c981a068d0608f744ac775998e4ebb6f/df56e/latamcham_4.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f7bd713a7ba16cfda49d0c60a8806593/df56e/latamcham_5.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADWElEQVQ4y6WS/2sTZxzH70/YLyJsiM6uZaat2prLab/4Ldr0S0ytblMRhDmh6gQHRUOpI7XfaErbbKsi62DdDwP3wxQGG8wxBitIpWWstEbPmniaS3KX+/bcc5fLXZK7eySXWnTTn3zx+eHhuffrHvh8PhhCyH4tCJXrTQGEELZ2+g8FLY9ko6gYet74/1dD17PZLFYoFh/FEmSMfhRPLkWfLEfjC4sPF5fIubsL83Pz8/fm7y0sRUlq4Z/lKEn9uxx7sJJ4TDFL0bggipgkwYZAqN7f727rxQ+PeTqHXY3d1U3drsYzG6r96zbtdfv73R2h7ft73K1XiI8jROfgdl9fy4kBNsNhQFb3HQsTXSPEoZDbd7mmufuddbUbKr24L3jwSG/bqUlPV3jX0bFdXcO4/yre3of7gjW7zx88GsxwAiYBpTHQ5z40sLNzoG7fhfc+8L67uXnjhy3vbzsenrodnvq5quFzvO3yjvYv6w5c2uH9gujoxQOD7SeHnJehtqcrhPuCHl+Pp/XS1qZPK2vbquqO4N6zx89OHvtsxPvJUONHI0Sgv+HwVaI1SHT0eQKD/tNfsSyHSXK2zttTvfti5c4z6ytatzR0b2k6V0WcrnCfqqg/Ud8SrNlz0dV8wdV0flvHUK0vtLXliqt99MDJiZKs5Yyxr2+ORm5Gvv21f/jG4Oh3w+M/hL/5aeLGL0OTP4anbkWmfxu/fnv82q3I939OTP9eqpnZqZk7PC9gtmWV52xZlihBAUBJhmmW50TAiYBhMgzDMRme5QSOFy3LKs85m1UBkDDLssxi0XTIF0pomsYLgioDKIkCBKIqi6Ko5XL5/GrMtm0AgCRJ5ZetF7toO8toIdv8I6H8zen5uTi8E7Vs86VAKaMo8PVyeZ3pbCFdRLagFhLiy/dvkE0TISRdm5Vn5spBGPkLvDiD6bvS9dmS7cRekUtlmRZCucVEgWRL/UNIW6SNaLos6/dT+nKqdO/IEMpr8mq347HYY5rSTKOQN1iWSQpMRuYNXWdZ5hmXzEBBz+XK/1JVZVVWVcVpnphM0s8oKplIsCzDskyKplM0zfMcRVErJLlCkk+fUjKQZBmk0ykAAOYMLSvLMoRQcVBVVXkV2QE6mTVM08TQW/BW8nNya7YeSfnpWwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Redesign",
            "title": "Redesign",
            "src": "/static/f7bd713a7ba16cfda49d0c60a8806593/5a190/latamcham_5.png",
            "srcSet": ["/static/f7bd713a7ba16cfda49d0c60a8806593/772e8/latamcham_5.png 200w", "/static/f7bd713a7ba16cfda49d0c60a8806593/e17e5/latamcham_5.png 400w", "/static/f7bd713a7ba16cfda49d0c60a8806593/5a190/latamcham_5.png 800w", "/static/f7bd713a7ba16cfda49d0c60a8806593/df56e/latamcham_5.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a462a0f194be5042bef7695caf1358b8/df56e/latamcham_6.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACFUlEQVQ4y7WUy2sTQRzHpyp4sGgv0Ut68aQgCk1jQaiK0R5SJRFTDx48+MCyiYhKYx4lRQRBBL0VjSL+A148eBMPHksFkdRGSA8VatOE3ZnZne3uPCW7MSjshoo4fBlmfsPn95gfM0D9wwD9jxljlFJ/dl2Xc74lWEqplGo0Gku1zqjX67Vardls9o5CYSGkL2LblmWZlkUp41xwLoSQW037r2vmQuiI6Lgjw7ShJ3/bghYmm8Gwn9KXxlokWRpOzw2nKvvOzkaS5UiyHE1VoqnK/syDy/dfS8G9yv+EuQcvLq+Cwze2xXNgRNtzOh9Nz+2dLA8czXUsR6aPX3+surAMgD/Vv4N4bsf4bTCWO3jpYab06nzhZWSyDI7dAiPayeknSooAWPTgsZs7T9wZmijsSswMJmZ2n7k3NFEYTOTBaPaU9jQY5qJjXfy6Cg5dG4hpYDTbVUwDMW17PAsOXB2/8igkstf9lbV2Ov9sqli9UHw+Vaz6yhSrF0svzt2dn51/072wwFZJKR2XYotgi0gvyO/uiWlSSkP7LKVEZBPZ1OHCoYwy1jLwjzZs6tgkjgGh67ohsJe5Taz3794ufPyA9Q2l1HobrrfhhoENTBBC4bAXmXO28m1p+fMC0lueRfxyq0zT7A93HgXnjHHKOROC+2KMKSURgo7jhMJCCBNjBCFCCHvqLTBC0DB6rxr8x5+kP/wTIGAe0tYSug4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Redesign",
            "title": "Redesign",
            "src": "/static/a462a0f194be5042bef7695caf1358b8/5a190/latamcham_6.png",
            "srcSet": ["/static/a462a0f194be5042bef7695caf1358b8/772e8/latamcham_6.png 200w", "/static/a462a0f194be5042bef7695caf1358b8/e17e5/latamcham_6.png 400w", "/static/a462a0f194be5042bef7695caf1358b8/5a190/latamcham_6.png 800w", "/static/a462a0f194be5042bef7695caf1358b8/df56e/latamcham_6.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9dfebdb5ae7ac6b11451a4ffca4be6c3/df56e/latamcham_7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACMklEQVQ4y6WU70tTURjH9+dIvQgkioolgZUDLbWEojkj8kfOYjoypMTe+UJjhFuwNtPZ1Be1qAgtN3phP0g2GuVGtCWK2S83du/uj3N3zj0/4nLbaLY2qefVgXM+5/t9nvM8x8DKBv0tCCFbdg3sP8LwN0HGGAAgFoslEolkMhmPx6PRKIRwO8oajDHmOC6TyfA8ryiKLMv6peVgSgnNLyBExee3nTNCKMtzsiSJoihJEgAAQogx3gpTSmVeTKc4Icv/CN1dfeh65x9ej4TmA97098+UMRUhjHFp24RShlSCsArBh+tNby7VLF47sTrnsVjOvo++ZYypqkr/SCAPEwKAJCtAynLLjvbwYP3SyPmV4KzTMfptY61Q/9IwpVQSRUEQRFFIr699ioTTXzcm7kzW1dV3dvX02uyNx5t9vildpmzBKEUKYoyNe7y7qnYcOWg83WDaX109MHBVf78ScHEjaieePX7QXFvTdsx0aM9u04G9gRm/plwSLgQhGMEcY+yez924r2r44pkrbS3mw8bw8/nKtgEAm6lNLpMJLQTHbzmdN0fbL3cPDvWPuVwvl159+RjXfZZrEoxULGudvJxINtjtp3o6W2zWJlv33P2ZQvENZYZRz3zhxeJJe7+lz2ruu3DU1uuYnv3VF5VgLb3A0yc7W82151o7bFZjV8fIbXdlGMJcOpWSBOF1JDzk8dzwuiemJsf804+Cwcq2CSEQQpgrmoR/+UmoPt+FQc/DPwHYODuCEJlHxQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Redesign",
            "title": "Redesign",
            "src": "/static/9dfebdb5ae7ac6b11451a4ffca4be6c3/5a190/latamcham_7.png",
            "srcSet": ["/static/9dfebdb5ae7ac6b11451a4ffca4be6c3/772e8/latamcham_7.png 200w", "/static/9dfebdb5ae7ac6b11451a4ffca4be6c3/e17e5/latamcham_7.png 400w", "/static/9dfebdb5ae7ac6b11451a4ffca4be6c3/5a190/latamcham_7.png 800w", "/static/9dfebdb5ae7ac6b11451a4ffca4be6c3/df56e/latamcham_7.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      